import * as React from "react";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { wpPostYoastSeo } from "../data/fragments";

import Layout from "../components/Layout";
import Section from "../components/Section";

import Article from "../components/Article";
import GetInTouch from "../components/GetInTouch";
import NewsAndInsights from "../components/NewsAndInsights";

const Post = ({ data }) => {
  return (
    <Layout>
      {/* SEO Data */}
      <Seo post={data.wpPost} />

      {/* GetInTouch Section */}
      <Section>
        <Article {...data.wpPost} />
      </Section>

      {/* GetInTouch Section */}
      <Section className="-mobile-full">
        <GetInTouch />
      </Section>

      {/* News and insights Section */}
      <Section>
        <NewsAndInsights />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query wpPost($id: String) {
    wpPost(id: { eq: $id }) {
      id
      title
      content
      excerpt
      date(formatString: "DD MMMM YYYY")
      categories {
        nodes {
          name
          slug
        }
      }
      # author {
      #   node {
      #     name
      #     nicename
      #   }
      # }
      postsRichContent {
        author
        additionalLinks {
          type
          link {
            target
            title
            url
          }
          text
          file {
            localFile {
              publicURL
            }
          }
        }
      }

      ...wpPostYoastSeo
    }
  }
`;

export default Post;
