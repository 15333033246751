// Default imports
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";

import IconFb from "../icons/icon-facebook.svg";
import IconIn from "../icons/icon-linkedin.svg";
import IconTw from "../icons/icon-twitter.svg";
import IconMa from "../icons/icon-envelope.svg";
import ButtonText from "./ButtonText";

const Article = ({ title, categories, content, date, postsRichContent }) => {
  const [currentUrl, setCurrentUrl] = useState(0);

  useEffect(() => {
    setCurrentUrl(window.location);
  });

  return (
    <div>
      {/* Article */}
      <Container className="article">
        <Row>
          <Col xs="24" lg="16">
            <div className="article__label">
              <div className="article__title">{categories.nodes[0].name}</div>
              <span>|</span>
              <div className="article__date">{date}</div>
            </div>
            <h1 className="article__header">{title}</h1>
            {postsRichContent?.author && (
              <p className="article__author">By {postsRichContent.author}</p>
            )}
            <p className="article__social-media">
              <TwitterShareButton
                className="article__icon-box"
                url={currentUrl}
                title={title}
              >
                <IconTw className="article__icon" />
              </TwitterShareButton>

              <LinkedinShareButton
                className="article__icon-box"
                url={currentUrl}
                title={title}
              >
                <IconIn className="article__icon" />
              </LinkedinShareButton>

              <FacebookShareButton
                className="article__icon-box"
                url={currentUrl}
              >
                <IconFb className="article__icon" />
              </FacebookShareButton>

              <EmailShareButton
                className="article__icon-box"
                url={currentUrl}
                subject={title}
                body="Check this article!"
                separator=" - "
                // Fix for opening in current window
                onClick={() => {}}
                openShareDialogOnClick={true}
              >
                <IconMa className="article__icon" />
              </EmailShareButton>
            </p>
            <div
              className="article__content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className="article__links">
              {postsRichContent &&
                postsRichContent.additionalLinks?.map(
                  ({ type, text, link, file }, index) => {
                    let btnLink = "";
                    if (type === "file") {
                      btnLink = {
                        url: file.localFile.publicURL,
                        title: text,
                        target: "_blank",
                      };
                    } else {
                      btnLink = link;
                    }
                    return (
                      <ButtonText
                        key={`article-links-item-${index}`}
                        className="article__links-item"
                        variant="red"
                        {...btnLink}
                      >
                        {btnLink?.title}
                      </ButtonText>
                    );
                  }
                )}
            </div>
          </Col>
          <div className="article__footer"></div>
        </Row>
      </Container>
    </div>
  );
};

export default Article;
